.editableProfileItemInvalidText {
  font-family: "Montserrat", sans-serif;
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

@media screen and (max-width: 600px) {
  .editableItemContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-self: center;
    align-items: center;
    overflow-x: hidden;
    padding-top: 20px;
    overflow-y: hidden;
    /* border-style: none none solid none;
      border-bottom-width: 1;
      border-color: lightgrey; */
  }

  .editableItemIconContainer {
    padding-bottom: 20px;
    display: flex;
    margin-right: 4%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .Link {
    text-decoration: none;
  }

  .itemTextButtonsDividerContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .itemTextButtonsContainer {
    display: flex; 
    flex-direction: row;
    align-items: center;
  }

  .editableItemTextContainer {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-content: flex-start;
  }

  .editableItemTextContainer > h1 {
    font-size: 16px;
    font-weight: 500;
    color: black;
  }

  .editableItemTextContainer > h2 {
    font-size: 14px;
    font-weight: 500;
    color: grey;
    margin-top: 2px;
  }

  .editableItemTextContainer > h3 {
    font-size: 12px;
    font-weight: 400;
    color: grey;
    margin-top: 2px;
  }

  .editableItemTextContainer > h4 {
    display: none;
  }

  .editDeleteButtonsContainer {
    /* margin-right: 1%; */
    margin-left: 1%;
    width: 21%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  .editDeleteButton {
    cursor: pointer;
  }

  .Divider {
    margin-top: 20px;
    /* margin-left: 10%; */
  }
  .experienceModal {
    background-color: white;
    position: absolute;
    left: 5%;
    right: 5%;
    /* bottom: 15%; */
    padding: 3% 2%;
    border-radius: 10px;
    outline: none;
  }

  .accomplishmentModal {
    background-color: white;
    position: absolute;
    left: 5%;
    right: 5%;
    padding: 3% 2%;
    border-radius: 10px;
    outline: none;
  }

  .measurableModal {
    background-color: white;
    position: absolute;
    left: 5%;
    right: 5%;
    padding: 3% 2%;
    border-radius: 10px;
    outline: none;
  }

  .editModal {
    background-color: white;
    position: absolute;
    top: 5%;
    max-width: calc(100vw - 2rem);
    max-height: calc(100vh - 2rem);
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.25);
    overflow-y: auto;
    left: 5%;
    right: 5%;
    bottom: 5%;
    padding: 2% 2%;
    border-radius: 10px;
    outline: none;
  }

  .itemAddModalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(34, 31, 31, 0.8);
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .expModalContainer {
    height: 70%;
  }

  .modalHeaderContainer {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 5%;
  }

  .modalHeaderContainer > p {
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
    font-size: large;
  }

  .textInputHeaders {
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 5px;
    font-family: "Open Sans", sans-serif;
  }

  .modalTextInputItems {
    width: 100%;
    padding: 8px;
    font-family: "Open Sans", sans-serif;
    outline: none;
  }

  .modalTextInputItems:hover {
    border-width: 2px;
    border-color: #3eb489;
  }

  .modalTextInputItems:focus {
    border-width: 2px;
    border-color: #3eb489;
    border-style: solid;
  }

  .modalDatePickerContainer {
    display: flex;
    flex-direction: column;
    margin-top: 2%;
    justify-content: space-between;
  }

  .expModalDatePickerItemContainer {
    width: 100%;
  }

  .accomplishmentMeasurableDatePickerItemContainer {
    width: 100%;
  }

  .modalDatePicker {
    /* padding-right: 89.9px; */
    width: 48%;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 2px;
  }

  .modalDatePicker:hover {
    border-width: 2px;
    border-color: #3eb489;
  }

  .modalDatePicker:focus {
    border-width: 2px;
    border-color: #3eb489;
  }

  .datePickerRow {
    display: flex;
  }

  .datePickerRowMiddleDivider {
    width: 4%;
  }

  .addEditItemModalButton {
    background-color: #3eb489;
    width: 100%;
    height: 50px;
    border-radius: 12px;
    border: none;
    font-weight: bold;
    font-size: 16px;
    font-family: "Montserrat", sans-serif;
    margin-top: 20px;
  }

  .addEditItemModalButton:hover {
    cursor: pointer;
    background-color: #22d694;
    color: #000;
    transition: 0.5s ease-in-out;
  }

  .presentTimeText {
    font-size: 11px;
    font-family: "Open Sans", sans-serif;
    font-weight: 500;
    margin-top: 2px;
    margin-left: 1px;
  }

  .presentTimeText > span {
    text-decoration: underline;
    font-weight: 600;
  }

  .presentTimeText > span:hover {
    cursor: pointer;
  }
}

@media screen and (max-width: 1200px) and (min-width: 600px) {
  .editableItemContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-self: center;
    align-items: center;
    overflow-x: hidden;
    padding-top: 20px;
    overflow-y: hidden;
    /* border-style: none none solid none;
      border-bottom-width: 1;
      border-color: lightgrey; */
  }

  .editableItemIconContainer {
    padding-bottom: 20px;
    display: flex;
    margin-right: 3%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .Link {
    text-decoration: none;
  }

  .itemTextButtonsDividerContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .itemTextButtonsContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .editableItemTextContainer {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-content: flex-start;
  }

  .editableItemTextContainer > h1 {
    font-size: 16px;
    font-weight: 500;
    color: black;
  }

  .editableItemTextContainer > h2 {
    font-size: 14px;
    font-weight: 500;
    color: grey;
    margin-top: 2px;
  }

  .editableItemTextContainer > h3 {
    font-size: 12px;
    font-weight: 400;
    color: grey;
    margin-top: 2px;
  }

  .editableItemTextContainer > h4 {
    font-size: 14px;
    font-weight: 400;
    color: black;
    margin-top: 10px;
  }

  .editDeleteButtonsContainer {
    margin-right: 2%;
    margin-left: 4%;
    width: 10%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  .editDeleteButton {
    cursor: pointer;
  }

  .Divider {
    margin-top: 20px;
    /* margin-left: 10%; */
  }
}

@media screen and (min-width: 1200px) {
  .editableItemContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-self: center;
    align-items: center;
    overflow-x: hidden;
    padding-top: 20px;
    overflow-y: hidden;
    /* border: solid; */
    /* border-style: none none solid none;
      border-bottom-width: 1;
      border-color: lightgrey; */
  }

  .editableItemIconContainer {
    padding-bottom: 20px;
    display: flex;
    margin-right: 3%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .Link {
    text-decoration: none;
  }

  .itemTextButtonsDividerContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .itemTextButtonsContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .editableItemTextContainer {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-content: flex-start;
  }

  .editableItemTextContainer > h1 {
    font-size: 16px;
    font-weight: 500;
    color: black;
  }

  .editableItemTextContainer > h2 {
    font-size: 14px;
    font-weight: 500;
    color: grey;
    margin-top: 2px;
  }

  .editableItemTextContainer > h3 {
    font-size: 12px;
    font-weight: 400;
    color: grey;
    margin-top: 2px;
  }

  .editableItemTextContainer > h4 {
    font-size: 14px;
    font-weight: 400;
    color: black;
    margin-top: 10px;
  }

  .editDeleteButtonsContainer {
    margin-right: 2%;
    margin-left: 4%;
    width: 10%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  .editDeleteButton {
    cursor: pointer;
  }

  .Divider {
    margin-top: 20px;
    /* margin-left: 10%; */
  }

  /* Modals */
  .itemAddModalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(34, 31, 31, 0.8);
  }

  .experienceModal {
    background-color: white;
    position: absolute;
    left: 25%;
    right: 25%;
    /* bottom: 15%; */
    padding: 2% 2%;
    border-radius: 10px;
    outline: none;
  }

  .accomplishmentModal {
    background-color: white;
    position: absolute;
    left: 25%;
    right: 25%;
    padding: 2% 2%;
    border-radius: 10px;
    outline: none;
  }

  .measurableModal {
    background-color: white;
    position: absolute;
    left: 25%;
    right: 25%;
    padding: 2% 2%;
    border-radius: 10px;
    outline: none;
  }

  .modalHeaderContainer {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 5%;
  }

  .modalHeaderContainer > p {
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
    font-size: large;
  }

  .textInputHeaders {
    font-size: 14px;
    margin-top: 2%;
    margin-bottom: 5px;
    font-family: "Open Sans", sans-serif;
  }

  .modalTextInputItems {
    width: 100%;
    padding: 8px;
    font-family: "Open Sans", sans-serif;
    outline: none;
  }

  .modalTextInputItems:hover {
    border-width: 2px;
    border-color: #3eb489;
  }

  .modalTextInputItems:focus {
    border-width: 2px;
    border-color: #3eb489;
    border-style: solid;
  }

  .modalDatePickerContainer {
    display: flex;
    margin-top: 2%;
    justify-content: space-between;
  }

  .expModalDatePickerItemContainer {
    width: 50%;
  }

  .accomplishmentMeasurableDatePickerItemContainer {
    width: 100%;
  }

  .modalDatePicker {
    /* padding-right: 89.9px; */
    width: 48%;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 2px;
  }

  .modalDatePicker:hover {
    border-width: 2px;
    border-color: #3eb489;
  }

  .modalDatePicker:focus {
    border-width: 2px;
    border-color: #3eb489;
  }

  .datePickerRow {
    display: flex;
  }

  .datePickerRowMiddleDivider {
    width: 4%;
  }

  .addEditItemModalButton {
    background-color: #3eb489;
    width: 100%;
    height: 50px;
    border-radius: 12px;
    border: none;
    font-weight: bold;
    font-size: 16px;
    font-family: 'Montserrat', sans-serif;
    margin-top: 2%;
  }

  .addEditItemModalButton:hover {
    cursor: pointer;
    background-color: #22d694;
    color: #000;
    transition: 0.5s ease-in-out;
  }

  .presentTimeText {
    font-size: 11px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 500;
    margin-top: 2px;
    margin-left: 1px;
  }

  .presentTimeText > span {
    text-decoration: underline;
    font-weight: 600;
  }

  .presentTimeText > span:hover {
    cursor: pointer;
  }
}
