@media screen and (max-width: 600px) {
  .viewableItemContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-self: center;
    align-items: center;
    overflow-x: hidden;
    /* padding-top: 20px; */
    overflow-y: hidden;
  }

  .viewableItemIconContainer {
    display: flex;
    margin-right: 5%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* margin-top: 7px; */
  }

  .Link {
    text-decoration: none;
  }

  .viewableItemTextContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-content: flex-start;
    margin-top: 20px;
  }

  .viewableItemTextContainer > h1 {
    font-size: 16px;
    font-weight: 500;
    color: black;
  }

  .viewableItemTextContainer > h2 {
    font-size: 14px;
    font-weight: 500;
    color: grey;
    margin-top: 2px;
  }

  .viewableItemTextContainer > h3 {
    font-size: 12px;
    font-weight: 400;
    color: grey;
    margin-top: 2px;
  }

  .viewableItemTextContainer > h4 {
    display: none;
  }

  .viewableItemDivider {
    margin-top: 20px;
    width: 100%;
    /* margin-left: 10%; */
  }
}

@media screen and (max-width: 1200px) and (min-width: 600px) {
  .viewableItemContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-self: center;
    align-items: center;
    overflow-x: hidden;
    padding-top: 20px;
    overflow-y: hidden;
    /* border-style: none none solid none;
    border-bottom-width: 1;
    border-color: lightgrey; */
  }

  .viewableItemIconContainer {
    padding-bottom: 20px;
    display: flex;
    margin-right: 3%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* margin-top: 7px; */
  }

  .Link {
    text-decoration: none;
  }

  .viewableItemTextContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-content: flex-start;
  }

  .viewableItemTextContainer > h1 {
    font-size: 16px;
    font-weight: 500;
    color: black;
  }

  .viewableItemTextContainer > h2 {
    font-size: 14px;
    font-weight: 500;
    color: grey;
    margin-top: 2px;
  }

  .viewableItemTextContainer > h3 {
    font-size: 12px;
    font-weight: 400;
    color: grey;
    margin-top: 2px;
  }

  .viewableItemTextContainer > h4 {
    font-size: 14px;
    font-weight: 400;
    color: black;
    margin-top: 10px;
  }

  .viewableItemDivider {
    margin-top: 20px;
    width: 100%;
    /* margin-left: 10%; */
  }
}

@media screen and (min-width: 1200px) {
  .viewableItemContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-self: center;
    align-items: center;
    overflow-x: hidden;
    padding-top: 20px;
    overflow-y: hidden;
  }

  .viewableItemIconContainer {
    padding-bottom: 20px;
    display: flex;
    margin-right: 3%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* margin-top: 7px; */
  }

  .Link {
    text-decoration: none;
  }

  .viewableItemTextContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-content: flex-start;
    /* border-bottom: solid;
    border-bottom-color: lightgrey; */
    /* padding: 2% 0%; */
    /* border-bottom-width: 2px; */
  }

  .viewableItemTextContainer > h1 {
    font-size: 16px;
    font-weight: 500;
    color: black;
  }

  .viewableItemTextContainer > h2 {
    font-size: 14px;
    font-weight: 500;
    color: grey;
    margin-top: 2px;
  }

  .viewableItemTextContainer > h3 {
    font-size: 12px;
    font-weight: 400;
    color: grey;
    margin-top: 2px;
  }

  .viewableItemTextContainer > h4 {
    font-size: 14px;
    font-weight: 400;
    color: black;
    margin-top: 10px;
  }

  .viewableItemDivider {
    margin-top: 20px;
    width: 100%;
    /* margin-left: 10%; */
  }
}
