.navbar {
  background: white;
  height: 80px;
  padding: 50px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 999;
  margin-bottom: 2%;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  height: 100px;
}

.headerSpogoLogo > img {
  object-fit: contain;
  height: 45px;
  /* position: absolute; */
}

.container {
  z-index: 1;
  width: 100%;
  /* max-width: 1300px; */
  margin-right: auto;
  margin-left: auto;
  padding-right: 50px;
  padding-left: 50px;
}

.headerSpogoLogo {
  color: #fff;
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
}

.navbar-icon {
  margin-right: 0.5rem;
}

.nav-menu {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  text-align: center;
  justify-content: end;
}

.headerItem {
  height: 80px;
}

.headerLinks:hover {
  color: #3eb489;
  cursor: pointer;
}

.headerLinks {
  color: black;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  font-size: 16px;
  font-weight: bold;
  font-family: 'Montserrat', sans-serif;
}

.headerButton > button {
  background-color: #3eb489;
  width: 200px;
  height: 48px;
  border-radius: 12px;
  border: none;
  font-weight: bold;
  font-size: 16px;
  font-family: 'DM Sans', sans-serif;
  margin-left: 20px;
  font-family: 'Montserrat', sans-serif;
}

.headerButton > button:hover {
  cursor: pointer;
  background-color: #22d694;
  color: #000;
  transition: 0.5s ease-in-out;
}

.loginHeaderButton > button {
  background-color: white;
  width: 200px;
  height: 48px;
  border-radius: 12px;
  font-weight: bold;
  font-size: 16px;
  font-family: 'DM Sans', sans-serif;
  margin-left: 20px;
  font-family: 'Montserrat', sans-serif;
  border: 2px solid #3eb489;
}

.loginHeaderButton > button:hover {
  cursor: pointer;
  background-color: #3eb489;
  color: #000;
  transition: 0.5s ease-in-out;
  
}

.fa-bars {
  color: #fff;
}

.headerMenuIcon {
  display: none;
}

.waitlistLinkBtn {
  text-decoration: none;
}

.loginLinkButton {
  text-decoration: none;
}

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    background: #3eb489;
  }

  .nav-menu.active {
    background: white;
    left: 0;
    opacity: 1;
    transition: all 0.6s ease;
    z-index: 1;
  }

  .headerLinks {
    text-align: center;
    font-size: 25px;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .headerLinks:hover {
    color: #3eb489;
    transform: scale(1.2);
    transition: all 0.3s ease;
  }

  .headerItem:hover {
    border: none;
  }

  .headerItem {
    width: 100%;
  }

  .headerSpogoLogo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 50%);
    /* display: none; */
  }

  .headerMenuIcon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }

  .headerButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 250px;
    height: 120px;
    text-decoration: none;
    margin-left: -20px;
  }

  .loginHeaderButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 250px;
    height: 120px;
    text-decoration: none;
    margin-left: -20px;
    margin-bottom: -50px;
  }

  .loginHeaderButton > button {
    background-color: white;
    width: 250px;
    height: 48px;
    border-radius: 12px;
    font-weight: bold;
    font-size: 16px;
    font-family: 'Montserrat', sans-serif;
    border: 2px solid #3eb489;
  }

  .loginHeaderButton > button:hover {
    cursor: pointer;
    background-color: #3eb489;
    color: #000;
    transition: 0.5s ease-in-out;
  }

  .headerButton > button {
    background-color: #3eb489;
    width: 250px;
    height: 48px;
    border-radius: 12px;
    border: none;
    font-weight: bold;
    font-size: 16px;
    font-family: 'Montserrat', sans-serif;
  }

  .headerButton > button:hover {
    cursor: pointer;
    background-color: #22d694;
    color: #000;
    transition: 0.5s ease-in-out;
  }
}
