.signUpInvalidText {
  font-family: "Montserrat", sans-serif;
  color: red;
  font-size: 12px;
  margin-top: -15px;
  margin-bottom: 15px;
}

.signUpFailedAlert {
  background-color: #FFD2DA;
  margin-top: 4%;
  margin-bottom: -4%;
  padding: 15px 20px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
}

@media screen and (max-width: 600px) {
  .signUpContainer {
    margin-top: 10%;
    display: flex;
    height: 100vh;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .signUpDesign1 {
    display: none;
  }
  
  .signUpDesign2 {
    display: none;
  }
  
  .signUpFormContainer {
    width: 100%;
    height: 100%;
    transition: 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
    padding: 4% 8%;
    display: flex;
    flex-direction: column;
  }
  
  .signUpTextInput {
    height: 50px;
    width: 100%;
    padding: 16px;
    border: 1px solid black;
    border-radius: 5px;
    color: #000;
    font-size: 14px;
    font-weight: 400;
    outline: none;
    transition: 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    margin-bottom: 20px;
  }
  
  .signUpTextInput:focus {
    border-width: 2px;
    border-color: #3eb489;
    color: black;
  }
  
  .signUpCreateAccountButton {
    background-color: #3eb489;
    height: 50px;
    border-radius: 12px;
    border: none;
    font-weight: bold;
    font-size: 16px;
    width: 100%;
    font-family: 'Montserrat', sans-serif;
  }
  
  .signUpCreateAccountButton:hover {
    cursor: pointer;
    background-color: #22d694;
    color: #000;
    transition: 0.5s ease-in-out;
  }
  
  .signUpFormHeader {
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    font-size: 30px;
    margin-bottom: 2%;
    color: #3eb489;
  }
  
  .signUpHeadlineHeader {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
  }
  .signUpTextInputHeader {
    margin-bottom: 5px;
    font-size: 12px;
    font-family: 'Open Sans', sans-serif;
  }
  
  .signUpHeadlineHeaderLastWord {
    font-weight: bold;
    color: #3eb489;
  }
  
  .signUpHaveAccountText {
    font-size: 12px;
    margin-top: 5%;
    text-align: center;
  }
  
  .signUpHaveAccountTextSpan {
    font-weight: 500;
    text-decoration: underline;
    color: #3eb489;
    cursor: pointer;
  }
  
  .signUpImageContainer {
    display: none;
  }
  
  .signUpImage {
    object-fit: contain;
    height: 600px;
    margin-left: 10%;
  }

  .googleSignUpContainer {
    display: flex;
    width: 100%;
    /* align-items: center; */
    justify-content: center;
    border: 1px solid black;
    border-radius: 12px;
    margin-top: 20px;
    height: 50px;
    align-items: center;
    transition: top ease 0.5s;
    position: relative;
  }

  .googleSignUpContainer:hover {
    background-color: #e8ecee;
    cursor: pointer;
    top: -5px;
    transition: 0.2s ease-in-out;

  }

  .googleImageSignUp {
    object-fit: contain;
    height: 20px;
  }

  .googleTextSignUp {
    font-size: 14px;
    margin-left: 20px;
    margin-bottom: 1px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
  }
}

@media screen and (max-width: 1200px) and (min-width: 600px) {
  .signUpContainer {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .signUpDesign1 {
    display: none;
  }
  
  .signUpDesign2 {
    display: none;
  }
  
  .signUpFormContainer {
    border: 1px solid transparent;
    border-radius: 10px;
    width: 420px;
    height: auto;
    box-shadow: 0 4px 8px 0 rgba(66, 65, 65, 0.203),
      0 6px 20px 0 rgba(161, 155, 155, 0.59);
    transition: 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
    padding: 4% 3%;
    display: flex;
    flex-direction: column;
    z-index: 1;
  }
  
  .signUpTextInput {
    height: 50px;
    width: 100%;
    padding: 16px;
    border: 1px solid black;
    border-radius: 5px;
    color: #000;
    font-size: 14px;
    font-weight: 400;
    outline: none;
    transition: 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    margin-bottom: 20px;
  }
  
  .signUpTextInput:focus {
    border-width: 2px;
    border-color: #3eb489;
    color: black;
  }
  
  .signUpCreateAccountButton {
    background-color: #3eb489;
    height: 50px;
    border-radius: 12px;
    border: none;
    font-weight: bold;
    font-size: 16px;
    width: 100%;
    font-family: 'Montserrat', sans-serif;
  }
  
  .signUpCreateAccountButton:hover {
    cursor: pointer;
    background-color: #22d694;
    color: #000;
    transition: 0.5s ease-in-out;
  }
  
  .signUpFormHeader {
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    font-size: 30px;
    margin-bottom: 2%;
    color: #3eb489;
  }
  
  .signUpHeadlineHeader {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
  }
  .signUpTextInputHeader {
    margin-bottom: 5px;
    font-size: 12px;
    font-family: 'Open Sans', sans-serif;
  }
  
  .signUpHeadlineHeaderLastWord {
    font-weight: bold;
    color: #3eb489;
  }
  
  .signUpHaveAccountText {
    font-size: 12px;
    margin-top: 5%;
    text-align: center;
  }
  
  .signUpHaveAccountTextSpan {
    font-weight: 500;
    text-decoration: underline;
    color: #3eb489;
    cursor: pointer;
  }
  
  .signUpImageContainer {
    display: none;
    width: 50%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .signUpImage {
    object-fit: contain;
    height: 600px;
    margin-left: 10%;
  }

  .googleSignUpContainer {
    display: flex;
    width: 100%;
    /* align-items: center; */
    justify-content: center;
    border: 1px solid black;
    border-radius: 12px;
    margin-top: 20px;
    height: 50px;
    align-items: center;
    transition: top ease 0.5s;
    position: relative;
  }

  .googleSignUpContainer:hover {
    background-color: #e8ecee;
    cursor: pointer;
    top: -5px;
    transition: 0.2s ease-in-out;

  }

  .googleImageSignUp {
    object-fit: contain;
    height: 20px;
  }

  .googleTextSignUp {
    font-size: 14px;
    margin-left: 20px;
    margin-bottom: 1px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
  }
}

@media screen and (min-width: 1200px) {
  .signUpContainer {
    display: flex;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .signUpDesign1 {
    display: none;
  }
  
  .signUpDesign2 {
    display: none;
  }
  
  .signUpFormContainer {
    border: 1px solid transparent;
    border-radius: 10px;
    width: 500px;
    height: auto;
    box-shadow: 0 4px 8px 0 rgba(66, 65, 65, 0.203),
      0 6px 20px 0 rgba(161, 155, 155, 0.59);
    transition: 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
    /* margin-left: 5%; */
    /* margin-top: 7%; */
    /* margin-top: 70px; */
    margin-right: 5%;
    padding: 4% 2.5%;
    display: flex;
    flex-direction: column;
    z-index: 1;
    background-color: white;
  }
  
  .signUpTextInput {
    height: 50px;
    width: 100%;
    padding: 16px;
    border: 1px solid black;
    border-radius: 5px;
    color: #000;
    font-size: 14px;
    font-weight: 400;
    outline: none;
    transition: 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    margin-bottom: 20px;
  }
  
  .signUpTextInput:focus {
    border-width: 2px;
    border-color: #3eb489;
    color: black;
  }
  
  .signUpCreateAccountButton {
    background-color: #3eb489;
    height: 50px;
    border-radius: 12px;
    border: none;
    font-weight: bold;
    font-size: 16px;
    width: 100%;
    font-family: 'Montserrat', sans-serif;
  }
  
  .signUpCreateAccountButton:hover {
    cursor: pointer;
    background-color: #22d694;
    color: #000;
    transition: 0.5s ease-in-out;
  }
  
  .signUpFormHeader {
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    font-size: 30px;
    margin-bottom: 2%;
    color: #3eb489;
  }
  
  .signUpHeadlineHeader {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
  }
  .signUpTextInputHeader {
    margin-bottom: 5px;
    font-size: 12px;
    font-family: 'Open Sans', sans-serif;
  }
  
  .signUpHeadlineHeaderLastWord {
    font-weight: bold;
    color: #3eb489;
  }
  
  .signUpHaveAccountText {
    font-size: 12px;
    margin-top: 5%;
    text-align: center;
  }
  
  .signUpHaveAccountTextSpan {
    font-weight: 500;
    text-decoration: underline;
    color: #3eb489;
    cursor: pointer;
  }
  
  .signUpImageContainer {
    display: flex;
    width: 50%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .signUpImage {
    object-fit: contain;
    height: 600px;
    margin-left: 10%;
  }

  .googleSignUpContainer {
    display: flex;
    width: 100%;
    /* align-items: center; */
    justify-content: center;
    border: 1px solid black;
    border-radius: 12px;
    margin-top: 20px;
    height: 50px;
    align-items: center;
  }

  .googleSignUpContainer:hover {
    background-color: whitesmoke;
    cursor: pointer;
    transition: 0.2s ease-in-out;

  }

  .googleImageSignUp {
    object-fit: contain;
    height: 20px;
  }

  .googleTextSignUp {
    font-size: 14px;
    margin-left: 20px;
    margin-bottom: 1px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
  }
}

