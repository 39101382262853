.loadingModal {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  width: 100vw;
  outline: none;
  border: none;
}

.loadingSpogoLogo {
  object-fit: contain;
  width: 25%;
  margin-bottom: -10%;
  display: flex;
  z-index: 1000;
}

.createScreenInvalidText {
  font-family: "Montserrat", sans-serif;
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

@media screen and (max-width: 600px) {
  .profileScreenContainer {
    align-items: center;
    width: 100%;
    overflow-x: hidden;
    background-color: whitesmoke;
    height: 100vh;
  }

  .profileContentContainer {
    width: 100%;
  }

  .createScreenProfileHeader {
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
    background-color: #3eb489;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .createScreenProfileImage {
    border-radius: 1000px;
    border: 3px solid white;
    object-fit: cover;
    width: 131.25px;
    height: 131.25px;
    margin-top: 5px;
  }

  .createScreenProfileTextContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 5px;
  }

  .createScreenNameSportTextContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    /* margin-bottom: 1%; */
  }

  .createScreenWebsiteUserName {
    font-size: 20px;
    color: black;
    font-weight: 500;
  }

  .createScreenWebsiteSportPositionText {
    font-size: 16px;
    color: black;
    font-weight: 480;
    margin-top: 5px;
  }

  .createScreenLocationIconTextContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    margin-top: 5px;
  }

  .createScreenLocationText {
    font-size: 14px;
    color: black;
    font-weight: 400;
    margin-left: 5px;
    /* text-align: center; */
  }

  .createScreenSocialIconsRow {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    margin-top: 2px;
    margin-bottom: 2%;

    /* justify-content: flex-start; */
  }

  .createScreenSocialIcon {
    margin-left: 2%;
    margin-right: 2%;
  }

  .createScreenSocialIcon:hover {
    /* margin-right: 5%; */
    cursor: pointer;
  }

  .createScreenBioContainer {
    margin-top: 20px;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 5%;
    padding: 0% 3%;
    padding-bottom: 20px;
    background-color: white;
    border-radius: 10px;
  }

  .createScreenBioContainer > h1 {
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-weight: bold;
    margin: 20px 0px;
  }

  .createScreenBioContainer > p {
    font-size: 14px;
    font-weight: 400;
    margin-top: 20px;
  }

  .createScreenComponentHeaderDivider {
    width: 100%;
  }

  .profileItemListHeaderContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .createScreenProfileItemListHeader {
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-weight: bold;
    margin: 20px 0px;
  }

  .profileItemListAddIcons {
    margin: 15px 0px;
  }

  .createScreenProfileItemListContainer {
    margin-top: 20px;
    width: 90%;
    display: flex;
    flex-direction: column;
    background-color: white;
    margin-left: 5%;
    border-radius: 10px;
    padding: 0% 3%;
  }

  .seeLessButton:hover {
    cursor: pointer;
  }

  .seeMoreButton:hover {
    cursor: pointer;
  }

  .seeMoreSeeLessItemButton {
    border: none;
    background-color: transparent;
    width: 106.5%;
    margin-top: -20px;
    /* margin: 0% -20%; */
    align-self: center;
    height: 60px;
    /* margin: 20px 0px; */
    color: #3eb489;
    font-size: 15px;
    font-weight: 500;
    font-family: "Montserrat", sans-serif;
  }

  .seeMoreSeeLessItemButton:hover {
    cursor: pointer;
    background-color: aliceblue;
  }

  .componentBottomDivider {
    width: 100%;
    margin-bottom: 20px;
    margin-top: -1px;
  }

  .spogoLogo {
    display: flex;
    /* width: 100%; */
    /* align-self: center;
          align-items: center; */
  }

  .spogoLogo > img {
    display: block;
    object-fit: contain;
    margin: 50px auto;
    width: 30%;
  }

  .spogoLogo > img:hover {
    cursor: pointer;
  }

  .bioModal {
    background-color: white;
    position: absolute;
    left: 5%;
    right: 5%;
    /* bottom: 15%; */
    padding: 3% 2%;
    border-radius: 10px;
    outline: none;
  }

  .experienceModal {
    background-color: white;
    position: absolute;
    left: 5%;
    right: 5%;
    /* bottom: 15%; */
    padding: 3% 2%;
    border-radius: 10px;
    outline: none;
  }

  .accomplishmentModal {
    background-color: white;
    position: absolute;
    left: 5%;
    right: 5%;
    padding: 3% 2%;
    border-radius: 10px;
    outline: none;
  }

  .measurableModal {
    background-color: white;
    position: absolute;
    left: 5%;
    right: 5%;
    padding: 3% 2%;
    border-radius: 10px;
    outline: none;
  }

  .profileEditModal {
    background-color: white;
    position: absolute;
    top: 5%;
    max-width: calc(100vw - 2rem);
    max-height: calc(100vh - 2rem);
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.25);
    overflow-y: auto;
    left: 5%;
    right: 5%;
    bottom: 5%;
    padding: 2% 15px;
    border-radius: 10px;
    outline: none;
  }

  .copyUrlModal {
    background-color: white;
    position: absolute;
    left: 5%;
    right: 5%;
    padding: 3% 2%;
    border-radius: 10px;
    outline: none;
  }

  .itemAddModalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(34, 31, 31, 0.8);
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .expModalContainer {
    height: 70%;
  }

  .modalHeaderContainer {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 5%;
  }

  .modalHeaderContainer > p {
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
    font-size: large;
  }

  .textInputHeaders {
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 5px;
    font-family: "Open Sans", sans-serif;
  }

  .modalTextInputItems {
    width: 100%;
    padding: 8px;
    font-family: "Open Sans", sans-serif;
    outline: none;
  }

  .modalTextInputItems:hover {
    border-width: 2px;
    border-color: #3eb489;
  }

  .modalTextInputItems:focus {
    border-width: 2px;
    border-color: #3eb489;
    border-style: solid;
  }

  .modalDatePickerContainer {
    display: flex;
    flex-direction: column;
    margin-top: 2%;
    justify-content: space-between;
  }

  .expModalDatePickerItemContainer {
    width: 100%;
  }

  .accomplishmentMeasurableDatePickerItemContainer {
    width: 100%;
  }

  .modalDatePicker {
    /* padding-right: 89.9px; */
    width: 48%;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 2px;
  }

  .modalDatePicker:hover {
    border-width: 2px;
    border-color: #3eb489;
  }

  .modalDatePicker:focus {
    border-width: 2px;
    border-color: #3eb489;
  }

  .datePickerRow {
    display: flex;
  }

  .datePickerRowMiddleDivider {
    width: 4%;
  }

  .addEditItemModalButton {
    background-color: #3eb489;
    width: 100%;
    height: 50px;
    border-radius: 12px;
    border: none;
    font-weight: bold;
    font-size: 16px;
    font-family: "Montserrat", sans-serif;
    margin-top: 20px;
  }

  .addEditItemModalButton:hover {
    cursor: pointer;
    background-color: #22d694;
    color: #000;
    transition: 0.5s ease-in-out;
  }

  .presentTimeText {
    font-size: 11px;
    font-family: "Open Sans", sans-serif;
    font-weight: 500;
    margin-top: 2px;
    margin-left: 1px;
  }

  .presentTimeText > span {
    text-decoration: underline;
    font-weight: 600;
  }

  .presentTimeText > span:hover {
    cursor: pointer;
  }

  .editModalSportPositionRowContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
    margin-top: 1%;
  }

  .editModalSportPositionRowItemsContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .copyUrlModalTitle {
    text-align: center;
    width: 100%;
  }

  .copyUrlModalTaglineText {
    text-align: center;
    margin-bottom: 20px;
  }

  .copyUrlTextInputContainer {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .closeIconCopyModal {
    cursor: pointer;
    display: none;
  }

  .copyUrlModalTextInputItem {
    width: 100%;
    height: 70px;
    border: none;
    border-bottom: 2px solid black;
    /* padding: 8px 15px; */
    padding-bottom: 2px;
    padding-left: 5px;
    padding-right: 5px;
    font-family: "Open Sans", sans-serif;
    outline: none;
    font-size: 20px;
    font-weight: 600;
    /* margin-bottom: 10px; */
  }

  .copyUrlSpogoText {
    font-family: "Open Sans", sans-serif;
    font-size: 20px;
    font-weight: 600;
    padding-bottom: 5px;
  }

  .copyUrlModalTextInputItem:hover {
    border-width: 2px;
    border-color: #3eb489;
  }

  .copyUrlModalTextInputItem:focus {
    border-width: 2px;
    border-color: #3eb489;
  }

  .topRightIconsContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 90%;
    margin-top: 15px;
    justify-content: space-between;

  }

  .topRightIconItem {
    margin-left: 5px;
    cursor: pointer;
    transition: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  .topRightIconItem:hover {
    transform: scale(1.05);
  }

  .settingsModal {
    /* background-color: white; */
    position: absolute;
    top: 50px;
    right: 2%;
    padding: 2% 2%;
    border-radius: 10px;
    outline: none;
  }

  .settingsItems {
    cursor: pointer;
    font-family: "Open Sans", sans-serif;
    width: 100%;
    color: white;
    font-size: 16px;
    font-weight: 500;
    text-align: right;
  }

  .settingsItemAddModalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparent;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .linkCopiedMessage {
    position: absolute;
    bottom: 2%;
    left: 2%;
    outline: none;
    border-radius: 5px;
    width: 125px;
    display: flex;
    justify-content: space-between;
    background-color: #04aa6d;
    padding: 1% 2%;
    align-items: center;
    box-shadow: 0 4px 8px 0 rgba(66, 65, 65, 0.203),
      0 6px 20px 0 rgba(161, 155, 155, 0.59);
    transition: 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  .linkCopiedMessage > p {
    font-family: "Open Sans", sans-serif;
    font-family: 14px;
  }
}

@media screen and (max-width: 1200px) and (min-width: 600px) {
  .profileScreenContainer {
    align-items: center;
    width: 100%;
    overflow-x: hidden;
    background-color: whitesmoke;
    height: 100vh;
  }

  .profileContentContainer {
    width: 100%;
  }

  .createScreenProfileHeader {
    margin-top: 25px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .createScreenProfileImage {
    border-radius: 1000px;
    border: 3px solid white;
    object-fit: cover;
    width: 120px;
    height: 120px;
  }

  .createScreenProfileTextContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 5px;
  }

  .createScreenNameSportTextContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    /* margin-bottom: 1%; */
  }

  .createScreenWebsiteUserName {
    font-size: 20px;
    color: black;
    font-weight: 500;
  }

  .createScreenWebsiteSportPositionText {
    font-size: 16px;
    color: black;
    font-weight: 480;
    margin-top: 5px;
  }

  .createScreenLocationIconTextContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    margin-top: 5px;
  }

  .createScreenLocationText {
    font-size: 14px;
    color: black;
    font-weight: 400;
    margin-left: 5px;
    /* text-align: center; */
  }

  .createScreenSocialIconsRow {
    display: flex;
    flex-direction: row;
    width: 250px;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 20px;

    /* justify-content: flex-start; */
  }

  .createScreenSocialIcon {
    margin: 0px 5px;
  }

  .createScreenSocialIcon:hover {
    /* margin-right: 5%; */
    cursor: pointer;
  }

  .createScreenBioContainer {
    margin-top: 20px;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 5%;
    padding: 0% 3%;
    padding-bottom: 20px;
    background-color: white;
    border-radius: 10px;
  }

  .createScreenBioContainer > h1 {
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-weight: bold;
    margin: 20px 0px;
  }

  .createScreenBioContainer > p {
    font-size: 14px;
    font-weight: 400;
    margin-top: 20px;
  }

  .createScreenComponentHeaderDivider {
    width: 100%;
  }

  .profileItemListHeaderContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .createScreenProfileItemListHeader {
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-weight: bold;
    margin: 20px 0px;
  }

  .profileItemListAddIcons {
    margin: 15px 0px;
  }

  .createScreenProfileItemListContainer {
    margin-top: 20px;
    width: 90%;
    display: flex;
    flex-direction: column;
    background-color: white;
    margin-left: 5%;
    border-radius: 10px;
    padding: 0% 3%;
  }

  .seeLessButton:hover {
    cursor: pointer;
  }

  .seeMoreButton:hover {
    cursor: pointer;
  }

  .seeMoreSeeLessItemButton {
    border: none;
    background-color: transparent;
    width: 106.5%;
    margin-top: -20px;
    /* margin: 0% -20%; */
    align-self: center;
    height: 60px;
    /* margin: 20px 0px; */
    color: #3eb489;
    font-size: 15px;
    font-weight: 500;
    font-family: "Montserrat", sans-serif;
  }

  .seeMoreSeeLessItemButton:hover {
    cursor: pointer;
    background-color: aliceblue;
  }

  .componentBottomDivider {
    width: 100%;
    margin-bottom: 20px;
    margin-top: -1px;
  }

  .spogoLogo {
    display: flex;
    /* width: 100%; */
    /* align-self: center;
          align-items: center; */
  }

  .spogoLogo > img {
    display: block;
    object-fit: contain;
    margin: 50px auto;
    width: 30%;
  }

  .spogoLogo > img:hover {
    cursor: pointer;
  }

  .bioModal {
    background-color: white;
    position: absolute;
    left: 5%;
    right: 5%;
    /* bottom: 15%; */
    padding: 3% 2%;
    border-radius: 10px;
    outline: none;
  }

  .experienceModal {
    background-color: white;
    position: absolute;
    left: 5%;
    right: 5%;
    /* bottom: 15%; */
    padding: 3% 2%;
    border-radius: 10px;
    outline: none;
  }

  .accomplishmentModal {
    background-color: white;
    position: absolute;
    left: 5%;
    right: 5%;
    padding: 3% 2%;
    border-radius: 10px;
    outline: none;
  }

  .measurableModal {
    background-color: white;
    position: absolute;
    left: 5%;
    right: 5%;
    padding: 3% 2%;
    border-radius: 10px;
    outline: none;
  }

  .profileEditModal {
    background-color: white;
    position: absolute;
    max-width: calc(100vw - 2rem);
    max-height: calc(100vh - 2rem);
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.25);
    overflow-y: auto;
    left: 5%;
    right: 5%;
    padding: 2% 2%;
    border-radius: 10px;
    outline: none;
  }

  .copyUrlModal {
    background-color: white;
    position: absolute;
    left: 30%;
    right: 30%;
    padding: 2% 2%;
    border-radius: 10px;
    outline: none;
  }

  .itemAddModalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(34, 31, 31, 0.8);
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .expModalContainer {
    height: 70%;
  }

  .modalHeaderContainer {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 5%;
  }

  .modalHeaderContainer > p {
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
    font-size: large;
  }

  .textInputHeaders {
    font-size: 14px;
    margin-top: 2%;
    margin-bottom: 5px;
    font-family: "Open Sans", sans-serif;
  }

  .modalTextInputItems {
    width: 100%;
    padding: 8px;
    font-family: "Open Sans", sans-serif;
    outline: none;
  }

  .modalTextInputItems:hover {
    border-width: 2px;
    border-color: #3eb489;
  }

  .modalTextInputItems:focus {
    border-width: 2px;
    border-color: #3eb489;
    border-style: solid;
  }

  .modalDatePickerContainer {
    display: flex;
    flex-direction: column;
    margin-top: 2%;
    justify-content: space-between;
  }

  .expModalDatePickerItemContainer {
    width: 100%;
  }

  .accomplishmentMeasurableDatePickerItemContainer {
    width: 100%;
  }

  .modalDatePicker {
    /* padding-right: 89.9px; */
    width: 48%;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 2px;
  }

  .modalDatePicker:hover {
    border-width: 2px;
    border-color: #3eb489;
  }

  .modalDatePicker:focus {
    border-width: 2px;
    border-color: #3eb489;
  }

  .datePickerRow {
    display: flex;
  }

  .datePickerRowMiddleDivider {
    width: 4%;
  }

  .addEditItemModalButton {
    background-color: #3eb489;
    width: 100%;
    height: 50px;
    border-radius: 12px;
    border: none;
    font-weight: bold;
    font-size: 16px;
    font-family: "Montserrat", sans-serif;
    margin-top: 2%;
  }

  .addEditItemModalButton:hover {
    cursor: pointer;
    background-color: #22d694;
    color: #000;
    transition: 0.5s ease-in-out;
  }

  .presentTimeText {
    font-size: 11px;
    font-family: "Open Sans", sans-serif;
    font-weight: 500;
    margin-top: 2px;
    margin-left: 1px;
  }

  .presentTimeText > span {
    text-decoration: underline;
    font-weight: 600;
  }

  .presentTimeText > span:hover {
    cursor: pointer;
  }

  .editModalSportPositionRowContainer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 1%;
  }

  .editModalSportPositionRowItemsContainer {
    display: flex;
    flex-direction: column;
    width: 48%;
  }

  .copyUrlModalTitle {
    text-align: center;
    width: 100%;
  }

  .closeIconCopyModal {
    cursor: pointer;
  }
  .copyUrlModalTaglineText {
    text-align: center;
    margin-bottom: 20px;
  }

  .copyUrlTextInputContainer {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .copyUrlModalTextInputItem {
    width: 100%;
    height: 70px;
    border: none;
    border-bottom: 2px solid black;
    /* padding: 8px 15px; */
    padding-bottom: 2px;
    padding-left: 5px;
    padding-right: 5px;
    font-family: "Open Sans", sans-serif;
    outline: none;
    font-size: 20px;
    font-weight: 600;
    /* margin-bottom: 10px; */
  }

  .copyUrlSpogoText {
    font-family: "Open Sans", sans-serif;
    font-size: 20px;
    font-weight: 600;
    padding-bottom: 5px;
  }

  .copyUrlModalTextInputItem:hover {
    border-width: 2px;
    border-color: #3eb489;
  }

  .copyUrlModalTextInputItem:focus {
    border-width: 2px;
    border-color: #3eb489;
  }

  .topRightIconsContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 90%;
    margin-top: 20px;
    justify-content: space-between;
  }

  .topRightIconItem {
    margin-left: 10px;
    cursor: pointer;
    transition: cubic-bezier(0.19, 1, 0.22, 1);
  }
  .topRightIconItem:hover {
    transform: scale(1.05);
  }

  .settingsModal {
    position: absolute;
    top: 90px;
    right: 2%;
    padding: 0% 2%;
    border-radius: 10px;
    outline: none;
  }

  .settingsItems {
    cursor: pointer;
    font-family: "Open Sans", sans-serif;
    width: 100%;
    color: #3eb489;
    font-weight: bold;
    text-align: right;
  }

  .settingsItemAddModalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparent;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .linkCopiedMessage {
    position: absolute;
    bottom: 2%;
    left: 2%;
    outline: none;
    border-radius: 5px;
    width: 150px;
    display: flex;
    justify-content: space-between;
    background-color: #04aa6d;
    padding: 1% 1%;
    align-items: center;
    box-shadow: 0 4px 8px 0 rgba(66, 65, 65, 0.203),
      0 6px 20px 0 rgba(161, 155, 155, 0.59);
    transition: 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  .linkCopiedMessage > p {
    font-family: "Open Sans", sans-serif;
    font-family: 14px;
  }
}

@media screen and (min-width: 1200px) {
  .profileScreenContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: whitesmoke;
    overflow-x: hidden;
    height: 100vh;
    align-items: center;
  }

  .profileContentContainer {
    width: 60%;
  }

  .profileScrollBarContainer {
    position: fixed;
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-top: 20%;
    margin-left: 5%;
    padding: 20px;
    border-radius: 10px;
    /* background-color: rgb(235, 223, 223); */
  }

  .profileSideBarItem {
    margin-top: 20px;
    transition: cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  .profileSideBarItem:hover {
    cursor: pointer;
    color: rgb(68, 50, 50);
    font-weight: bold;
  }

  .profileSideBarItem:active {
    transform: scale(1.05);
    font-size: 16;
    /* font-weight: bold; */
  }

  .profileChatRightTab {
    width: 20%;
    height: 200px;
    /* background-color: white; */
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .middleProfileContent {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 20%;
  }

  .createScreenProfileHeader {
    /* border: solid; */
    margin-top: 25px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    width: 100%;
    /* background-color: rgb(219, 208, 208); */
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .createScreenProfileImage {
    border-radius: 1000px;
    border: 3px solid white;
    object-fit: cover;
    width: 120px;
    height: 120px;
  }

  .createScreenProfileTextContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 5px;
  }

  .createScreenNameSportTextContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    /* margin-bottom: 1%; */
  }

  .createScreenWebsiteUserName {
    font-size: 20px;
    color: black;
    font-weight: 500;
  }

  .createScreenWebsiteSportPositionText {
    font-size: 16px;
    color: black;
    font-weight: 480;
    margin-top: 5px;
  }

  .createScreenLocationIconTextContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    margin-top: 5px;
  }

  .createScreenLocationText {
    font-size: 14px;
    color: black;
    font-weight: 400;
    margin-left: 5px;
    /* text-align: center; */
  }

  .createScreenSocialIconsRow {
    display: flex;
    flex-direction: row;
    width: 250px;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 20px;

    /* justify-content: flex-start; */
  }

  .createScreenSocialIcon {
    margin: 0px 5px;
  }

  .createScreenSocialIcon:hover {
    /* margin-right: 5%; */
    cursor: pointer;
  }

  .createScreenBioContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: center;
    padding: 0% 3%;
    padding-bottom: 20px;
    background-color: white;
  }

  .createScreenBioContainer > h1 {
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-weight: bold;
    margin: 20px 0px;
  }

  .createScreenBioContainer > p {
    font-size: 14px;
    font-weight: 400;
    margin-top: 20px;
  }

  .createScreenComponentHeaderDivider {
    width: 100%;
  }

  .profileItemListHeaderContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .createScreenProfileItemListHeader {
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-weight: bold;
    margin: 20px 0px;
  }

  .profileItemListAddIcons {
    margin: 15px 0px;
  }

  .createScreenProfileItemListContainer {
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: white;
    /* border-radius: 10px; */
    padding: 0% 3%;
  }

  .seeLessButton:hover {
    cursor: pointer;
  }

  .seeMoreButton:hover {
    cursor: pointer;
  }

  .seeMoreSeeLessItemButton {
    border: none;
    background-color: transparent;
    width: 106.5%;
    margin-top: -20px;
    /* margin: 0% -20%; */
    align-self: center;
    height: 60px;
    /* margin: 20px 0px; */
    color: #3eb489;
    font-size: 15px;
    font-weight: 500;
    font-family: "Montserrat", sans-serif;
  }

  .seeMoreSeeLessItemButton:hover {
    cursor: pointer;
    background-color: aliceblue;
  }

  .componentBottomDivider {
    width: 100%;
    margin-bottom: 20px;
    margin-top: -1px;
  }

  .spogoLogo {
    display: flex;
    /* width: 100%; */
    /* align-self: center;
          align-items: center; */
  }

  .spogoLogo > img {
    display: block;
    object-fit: contain;
    margin: 50px auto;
    width: 30%;
  }

  .spogoLogo > img:hover {
    cursor: pointer;
  }

  .userNotFoundErrorContainer {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .bioModal {
    background-color: white;
    position: absolute;
    left: 25%;
    right: 25%;
    /* bottom: 15%; */
    padding: 2% 2%;
    border-radius: 10px;
    outline: none;
  }

  .experienceModal {
    background-color: white;
    position: absolute;
    left: 25%;
    right: 25%;
    /* bottom: 15%; */
    padding: 2% 2%;
    border-radius: 10px;
    outline: none;
  }

  .accomplishmentModal {
    background-color: white;
    position: absolute;
    left: 25%;
    right: 25%;
    padding: 2% 2%;
    border-radius: 10px;
    outline: none;
  }

  .measurableModal {
    background-color: white;
    position: absolute;
    left: 25%;
    right: 25%;
    padding: 2% 2%;
    border-radius: 10px;
    outline: none;
  }

  .profileEditModal {
    background-color: white;
    position: absolute;
    top: 5%;
    max-width: calc(100vw - 2rem);
    max-height: calc(100vh - 2rem);
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.25);
    overflow-y: auto;
    left: 20%;
    right: 20%;
    bottom: 5%;
    padding: 2% 2%;
    border-radius: 10px;
    outline: none;
  }

  .copyUrlModal {
    background-color: white;
    position: absolute;
    left: 30%;
    right: 30%;
    padding: 2% 2%;
    border-radius: 10px;
    outline: none;
  }

  .itemAddModalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(34, 31, 31, 0.8);
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .expModalContainer {
    height: 70%;
  }

  .modalHeaderContainer {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 5%;
  }

  .modalHeaderContainer > p {
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
    font-size: large;
  }

  .textInputHeaders {
    font-size: 14px;
    margin-top: 2%;
    margin-bottom: 5px;
    font-family: "Open Sans", sans-serif;
  }

  .modalTextInputItems {
    width: 100%;
    padding: 8px;
    font-family: "Open Sans", sans-serif;
    outline: none;
  }

  .modalTextInputItems:hover {
    border-width: 2px;
    border-color: #3eb489;
    border-style: solid;
  }

  .modalTextInputItems:focus {
    border-width: 2px;
    border-color: #3eb489;
    border-style: solid;
  }

  .modalDatePickerContainer {
    display: flex;
    margin-top: 2%;
    justify-content: space-between;
  }

  .expModalDatePickerItemContainer {
    width: 50%;
  }

  .accomplishmentMeasurableDatePickerItemContainer {
    width: 100%;
  }

  .modalDatePicker {
    /* padding-right: 89.9px; */
    width: 48%;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 2px;
  }

  .modalDatePicker:hover {
    border-width: 2px;
    border-color: #3eb489;
  }

  .modalDatePicker:focus {
    border-width: 2px;
    border-color: #3eb489;
  }

  .datePickerRow {
    display: flex;
  }

  .datePickerRowMiddleDivider {
    width: 4%;
  }

  .addEditItemModalButton {
    background-color: #3eb489;
    width: 100%;
    height: 50px;
    border-radius: 12px;
    border: none;
    font-weight: bold;
    font-size: 16px;
    font-family: "Montserrat", sans-serif;
    margin-top: 2%;
  }

  .addEditItemModalButton:hover {
    cursor: pointer;
    background-color: #22d694;
    color: #000;
    transition: 0.5s ease-in-out;
  }

  .presentTimeText {
    font-size: 11px;
    font-family: "Open Sans", sans-serif;
    font-weight: 500;
    margin-top: 2px;
    margin-left: 1px;
  }

  .presentTimeText > span {
    text-decoration: underline;
    font-weight: 600;
  }

  .presentTimeText > span:hover {
    cursor: pointer;
  }

  .editModalSportPositionRowContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    margin-top: 1%;
  }

  .editModalSportPositionRowItemsContainer {
    display: flex;
    flex-direction: column;
    width: 48%;
  }

  .copyUrlModalTitle {
    text-align: center;
    width: 100%;
  }

  .copyUrlModalTaglineText {
    text-align: center;
    margin-bottom: 20px;
  }

  .closeIconCopyModal {
    cursor: pointer;
  }

  .copyUrlTextInputContainer {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .copyUrlModalTextInputItem {
    width: 100%;
    height: 70px;
    border: none;
    border-bottom: 2px solid black;
    /* padding: 8px 15px; */
    padding-bottom: 2px;
    padding-left: 5px;
    padding-right: 5px;
    font-family: "Open Sans", sans-serif;
    outline: none;
    font-size: 20px;
    font-weight: 600;
    /* margin-bottom: 10px; */
  }

  .copyUrlSpogoText {
    font-family: "Open Sans", sans-serif;
    font-size: 20px;
    font-weight: 600;
    padding-bottom: 5px;
  }

  .copyUrlModalTextInputItem:hover {
    border-width: 2px;
    border-color: #3eb489;
  }

  .copyUrlModalTextInputItem:focus {
    border-width: 2px;
    border-color: #3eb489;
  }

  .topRightIconsContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .topRightIconsContainer > h1 {
    font-size: 16px;
  }

  .topRightIconItem {
    margin-left: 13px;
    cursor: pointer;
    transition: cubic-bezier(0.19, 1, 0.22, 1);
  }

  .topRightIconItem:hover {
    transform: scale(1.05);
  }

  .settingsModal {
    position: absolute;
    top: 70px;
    right: 20%;
    /* padding: 2% 0%; */
    border-radius: 10px;
    outline: none;
  }

  .settingsItems {
    cursor: pointer;
    font-family: "Open Sans", sans-serif;
    width: 100%;
    color: #3eb489;
    font-weight: bold;
    text-align: right;
  }

  .settingsItemAddModalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparent;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .linkCopiedMessage {
    position: absolute;
    bottom: 2%;
    left: 2%;
    outline: none;
    border-radius: 5px;
    width: 200px;
    display: flex;
    justify-content: space-between;
    background-color: #04aa6d;
    padding: 1% 1%;
    align-items: center;
    box-shadow: 0 4px 8px 0 rgba(66, 65, 65, 0.203),
      0 6px 20px 0 rgba(161, 155, 155, 0.59);
    transition: 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  .linkCopiedMessage > p {
    font-family: "Open Sans", sans-serif;
    font-family: 14px;
  }
}
