.videoListHeaderText {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: bold;
  margin: 20px 0px;
}

.videoListDeleteIcon {
  margin: 15px 10px;
  cursor: pointer;
}

.videoListAddIcon {
  margin: 15px 0px;
  cursor: pointer;
}

.createScreenComponentHeaderDivider {
  width: 100%;
}

.videoItemArrayList {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  width: 100%;
  margin-bottom: 20px;
}

.videoListHeaderIconContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

@media screen and (max-width: 600px) {
  .videoListContainer {
    margin-top: 20px;
    width: 90%;
    display: flex;
    flex-direction: column;
    background-color: white;
    margin-left: 5%;
    border-radius: 10px;
    padding: 0% 3%;
  }

  .videoListHeaderContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .uploadProgressModalContainer {
    width: 100vw;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
  }

  .uploadProgressModalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.75);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .uploadProgressModalContent {
    border-radius: 5px;
    width: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #04aa6d;
    padding: 30px 20px;
    align-items: center;
    box-shadow: 0 4px 8px 0 rgba(66, 65, 65, 0.203),
      0 6px 20px 0 rgba(161, 155, 155, 0.59);
    transition: 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  .uploadProgressModalContent > p {
    font-family: 'Open Sans', sans-serif;
    font-family: 14px;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 1200px) and (min-width: 600px) {
  .videoListContainer {
    margin-top: 20px;
    width: 90%;
    display: flex;
    flex-direction: column;
    background-color: white;
    margin-left: 5%;
    border-radius: 10px;
    padding: 0% 3%;
  }

  .videoListHeaderContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .uploadProgressModalContainer {
    width: 100vw;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
  }

  .uploadProgressModalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.75);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .uploadProgressModalContent {
    border-radius: 5px;
    width: 250px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #04aa6d;
    padding: 30px 20px;
    align-items: center;
    box-shadow: 0 4px 8px 0 rgba(66, 65, 65, 0.203),
      0 6px 20px 0 rgba(161, 155, 155, 0.59);
    transition: 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  .uploadProgressModalContent > p {
    font-family: 'Open Sans', sans-serif;
    font-family: 14px;
    margin-bottom: 10px;
  }
}

@media screen and (min-width: 1200px) {
  .videoListContainer {
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: white;
    /* border-radius: 10px; */
    padding: 0% 3%;
  }

  .videoListHeaderContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .uploadProgressModalContainer {
    width: 100vw;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
  }

  .uploadProgressModalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.75);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .uploadProgressModalContent {
    border-radius: 5px;
    width: 250px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #04aa6d;
    padding: 30px 1%;
    align-items: center;
    box-shadow: 0 4px 8px 0 rgba(66, 65, 65, 0.203),
      0 6px 20px 0 rgba(161, 155, 155, 0.59);
    transition: 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  .uploadProgressModalContent > p {
    font-family: 'Open Sans', sans-serif;
    font-family: 14px;
    margin-bottom: 10px;
  }
}
