.subscribeFormContainer {
  width: 100%;
  margin-top: 10%;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 0 5%;
}

.subscribeText {
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 2%;
}

.subscribeInput {
  flex: 0.5;
  height: 50px;
  padding-left: 16px;
  padding-right: 300px;
  border: 0.5px solid #c7c7c7;
  border-radius: 12px;
  background-color: transparent;
  color: #000;
  font-size: 14px;
  font-weight: 400;
  outline: none;
  transition: 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  font-family: 'DM Sans', sans-serif;
  margin-bottom: 10px;
  margin-right: 2%;
}

.subscribeInput:focus {
  border-width: 2px;
  border-color: #3eb489;
  color: black;
}

.subscribeButton {
  background-color: #3eb489;
  height: 50px;
  border-radius: 12px;
  border: none;
  font-weight: bold;
  font-size: 16px;
  font-family: 'DM Sans', sans-serif;
  width: 200px;
}

.subscribeButton:hover {
  cursor: pointer;
  background-color: #22d694;
  color: #000;
  transition: 0.5s ease-in-out;
}

.subscribeForm {
  display: flex;
}

.subscribeForm > div {
  margin-right: 20px;
}

.invalidEmailContainer {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.invalidEmailText {
  color: red;
  font-size: 14px;
  text-align: center;
  font-weight: 500;
  margin-bottom: 2%;
}

.subscribeSuccessTextContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 60px;
}

.subscribeSuccessTextContainer > button {
  background-color: #3eb489;
  height: 50px;
  border-radius: 12px;
  border: none;
  font-weight: bold;
  font-size: 16px;
  font-family: 'DM Sans', sans-serif;
  width: 220px;
}

@media screen and (max-width: 750px) {
  .subscribeInput {
    flex: 0.5;
    height: 50px;
    padding-left: 16px;
    padding-right: 250px;
    border: 0.5px solid #c7c7c7;
    border-radius: 12px;
    background-color: transparent;
    color: #000;
    font-size: 14px;
    font-weight: 400;
    outline: none;
    transition: 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    font-family: 'DM Sans', sans-serif;
    margin-right: 2%;
  }
}

@media screen and (max-width: 700px) {
  .subscribeInput {
    flex: 0.5;
    height: 50px;
    padding-left: 16px;
    padding-right: 150px;
    border: 0.5px solid #c7c7c7;
    border-radius: 12px;
    background-color: transparent;
    color: #000;
    font-size: 14px;
    font-weight: 400;
    outline: none;
    transition: 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    font-family: 'DM Sans', sans-serif;
    margin-right: 2%;
  }
}

@media screen and (max-width: 550px) {
  .subscribeInput {
    flex: 0.5;
    height: 50px;
    padding-left: 16px;
    padding-right: 150px;
    border: 0.5px solid #c7c7c7;
    border-radius: 12px;
    background-color: transparent;
    color: #000;
    font-size: 14px;
    font-weight: 400;
    outline: none;
    transition: 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    font-family: 'DM Sans', sans-serif;
    margin-top: 5px;
  }
  .subscribeForm {
    display: flex;
    flex-direction: column;
  }

  .subscribeForm > div {
    margin-right: 0px;
  }

  .subscribeInput {
    padding-left: 16px;
    padding-right: 300px;
    padding-top: 16px;
    padding-bottom: 16px;
    border: 0.5px solid #c7c7c7;
    border-radius: 12px;
    background-color: transparent;
    color: #000;
    font-size: 14px;
    font-weight: 400;
    outline: none;
    transition: 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    font-family: 'DM Sans', sans-serif;
  }
  .subscribeButton {
    background-color: #3eb489;
    height: 50px;
    border-radius: 12px;
    border: none;
    font-weight: bold;
    font-size: 16px;
    font-family: 'DM Sans', sans-serif;
    width: 100%;
  }
}

@media screen and (max-width: 500px) {
  .subscribeInput {
    padding-left: 16px;
    padding-right: 200px;
    padding-top: 16px;
    padding-bottom: 16px;
    border: 0.5px solid #c7c7c7;
    border-radius: 12px;
    background-color: transparent;
    color: #000;
    font-size: 14px;
    font-weight: 400;
    outline: none;
    transition: 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    font-family: 'DM Sans', sans-serif;
  }
  .subscribeButton {
    background-color: #3eb489;
    height: 50px;
    border-radius: 12px;
    border: none;
    font-weight: bold;
    font-size: 16px;
    font-family: 'DM Sans', sans-serif;
    width: 100%;
  }
}

@media screen and (max-width: 400px) {
  .subscribeInput {
    padding-left: 16px;
    padding-right: 150px;
    padding-top: 16px;
    padding-bottom: 16px;
    border: 0.5px solid #c7c7c7;
    border-radius: 12px;
    background-color: transparent;
    color: #000;
    font-size: 14px;
    font-weight: 400;
    outline: none;
    transition: 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    font-family: 'DM Sans', sans-serif;
  }
}

@media screen and (max-width: 350px) {
  .subscribeInput {
    padding-left: 16px;
    padding-right: 70px;
    padding-top: 16px;
    padding-bottom: 16px;
    border: 0.5px solid #c7c7c7;
    border-radius: 12px;
    background-color: transparent;
    color: #000;
    font-size: 14px;
    font-weight: 400;
    outline: none;
    transition: 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    font-family: 'DM Sans', sans-serif;
  }
}
