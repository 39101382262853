.blogLink {
  text-decoration: none;
}

.blog_container {
  border: 1px solid transparent;
  border-radius: 10px;
  width: 45%;
  height: 500px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  transition: 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.blog_container:hover {
  transform: scale(1.05);
  cursor: pointer;
  border-radius: 20px;
}

.blog_image {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  width: 100%;
  height: 200px;
  transition: 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.blog_image:hover {
  cursor: pointer;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.noImageContainerLink {
  text-decoration: none;
}

.no_image_container {
  padding: 3% 5%;
}

.title {
  font-size: 29px;
  color: black;
  
}

.blog_writer_container {
  margin-top: 2%;
  margin-bottom: 2%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.blog_writer {
  object-fit: contain;
  height: 40px;
  border-radius: 1000px;
  margin-right: 10px;
}

.description_text {
  font-size: 16px;
  color: #666666;
}

.description_text > span {
  color: #3eb489;
}

.paragraph_text {
  font-size: 18px;
  color: black;
}

.read_more_text {
  margin-top: 2%;
  color: #3eb489;
  font-weight: 500;
  text-decoration: none;
}

@media screen and (max-width: 1050px) {
  .blog_container {
    border: 1px solid transparent;
    border-radius: 10px;
    width: 70%;
    height: 500px;
    align-self: center;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    transition: 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
    margin-top: 10%;
  }
}

@media screen and (max-width: 750px) {
  .blog_container {
    border: 1px solid transparent;
    border-radius: 10px;
    width: 80%;
    align-self: center;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    transition: 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
    margin-top: 10%;
  }
}

@media screen and (max-width: 650px) {
  .blog_container {
    border: 1px solid transparent;
    border-radius: 10px;
    width: 90%;
    align-self: center;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    transition: 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
    margin-top: 10%;
  }

  .title {
    font-size: 24px;
  }

}

@media screen and (max-width: 500px) {
  .blog_container {
    border: 1px solid transparent;
    border-radius: 10px;
    width: 95%;
    height: 50%;
    align-self: center;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    transition: 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
    margin-top: 10%;
  }

  .blog_image {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    width: 100%;
    height: 150px;
    transition: 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
}

@media screen and (max-width: 450px) {
  .blog_container {
    border: 1px solid transparent;
    border-radius: 10px;
    width: 100%;
    height: 50%;
    align-self: center;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    transition: 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
    margin-top: 10%;
  }

}
