@media screen and (max-width: 600px) {
  .ProfileScreenContainer {
    align-items: center;
    width: 100%;
    overflow-x: hidden;
    background-color: whitesmoke;
    height: 100vh;
  }

  .profileScrollBarContainer {
    display: none;
  }

  .profileHeader {
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
    background-color: #3eb489;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .profileHeader > div > h1 {
    font-size: 20px;
    color: black;
    font-weight: 600;
    margin-top: 5px;
  }

  .profileHeader > div > h2 {
    font-size: 16px;
    color: black;
    font-weight: 480;
    margin-top: 2px;
  }

  .locationIconTextContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    margin-top: 5px;
  }

  .locationText {
    font-size: 14px;
    color: black;
    font-weight: 400;
    margin-left: 5px;

    /* text-align: center; */
  }

  .profileImage {
    border-radius: 1000px;
    margin-top: 10%;
    border: 2px solid white;
    object-fit: cover;
    width: 131.25px;
    height: 131.25px;
  }

  .socialIconsRow {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    margin-top: 2px;
  }

  .socialIcon {
    margin-left: 2%;
    margin-right: 2%;
  }

  .bioContainer {
    margin-top: 20px;
    width: 90%;
    margin-left: 5%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: center;
    padding: 0% 3%;
    background-color: white;
    border-radius: 10px;
  }

  .bioContainer > h1 {
    font-size: 16px;
    font-weight: bold;
    margin: 20px 0px;
    font-family: 'Montserrat', sans-serif;
  }

  .bioContainer > p {
    font-size: 14px;
    font-weight: 400;
    margin: 20px 0px;
  }

  .bioDivider {
    margin-left: 5%;
    margin-right: 4%;
  }

  .seeMoreButton {
    border: none;
    background-color: white;
    font-size: 12px;
    font-weight: 600;
    color: lightgrey;
  }

  .seeLessButton {
    border: none;
    background-color: white;
    font-size: 12px;
    font-weight: 600;
    color: lightgrey;
    margin-top: 5px;
  }

  .nameSportTextContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .locationIconTextContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 5px;
  }

  .profileItemListHeader {
    font-size: 16px;
    font-weight: bold;
    margin: 20px 0px;
    font-family: 'Montserrat', sans-serif;
  }

  .profileItemListContainer {
    margin-top: 20px;
    width: 90%;
    display: flex;
    flex-direction: column;
    background-color: white;
    margin-left: 5%;
    border-radius: 10px;
    padding: 0% 3%;
  }

  .seeMoreSeeLessItemButton {
    border: none;
    background-color: transparent;
    width: 106.5%;
    /* margin: 0% -20%; */
    align-self: center;
    height: 60px;
    /* margin: 20px 0px; */
    color: #3eb489;
    font-size: 15px;
    font-weight: 500;
    margin-top: -20px;
    font-family: 'Montserrat', sans-serif;
  }

  .seeMoreSeeLessItemButton:hover {
    cursor: pointer;
    background-color: aliceblue;
  }

  .spogoLogo {
    display: flex;
    /* width: 100%; */
    /* align-self: center;
      align-items: center; */
  }

  .spogoLogo > img {
    display: block;
    object-fit: contain;
    margin: 50px auto;
    width: 30%;
  }

  .videoItemArrayList {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    margin-bottom: 8px;
  }

  .wildcardLinkModalContentContainer > h1 {
    margin: 4% 0%;
    font-size: 20px;
    font-family: 'Montserrat', sans-serif;
    text-align: center;
  }

  .wildcardLinkModalContentContainer > p {
    margin: 5% 0%;
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
    text-align: center;
  }

  .wildcardModalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(34, 31, 31, 0.8);
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .wildcardLinkModalContainer {
    background-color: white;
    position: absolute;
    left: 5%;
    right: 5%;
    padding: 3% 2%;
    border-radius: 10px;
    outline: none;
  }

  .wildcardLinkModalButtonsContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 5%;
  }

  .wildcardLinkModalButton {
    flex: 0.5;
    margin: 0% 2%;
    border: none;
    background-color: #3eb489;
    color: black;
    padding: 5% 0%;
    border-radius: 5px;
  }

  .componentHeaderDivider {
    width: 100%;
  }

  .componentBottomDivider {
    width: 100%;
    margin-bottom: 20px;
    margin-top: -1px;
  }

  .userNotFoundErrorContainer {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  .errorMessageContainer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 15%;
    /* justify-content: center; */
  }

  .errorMessageTextContainer {
    border: 1px solid transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    width: 100%;
    height: auto;
    /* margin-left: 5%; */
    /* margin-top: 7%; */
    margin: 0% 2%;
    padding: 4% 4%;
    display: flex;
    flex-direction: column;
    z-index: 1;
  }

  .errorHeaderText {
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
    font-size: 30px;
    margin-bottom: 2%;
    color: #3eb489;
  }

  .errorHeadlineText {
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
  }

  .returnHomeText {
    font-weight: 500;
    text-decoration: underline;
    color: #3eb489;
    cursor: pointer;
  }

  .errorImageContainer {
    display: none;
  }

  .errorImage {
    object-fit: contain;
    height: 600px;
    margin-left: 10%;
    /* margin-top: 10%; */
  }

  .userNotFoundSpogoLogo {
    display: flex;
    width: 100%;
  }

  .userNotFoundSpogoLogo > img {
    display: block;
    object-fit: contain;
    margin: 0px auto;
    width: 50%;
  }

  .userNotFoundSpogoLogo > img:hover {
    cursor: pointer;
  }
}

@media screen and (max-width: 1200px) and (min-width: 600px) {
  .ProfileScreenContainer {
    align-items: center;
    width: 100%;
    overflow-x: hidden;
    background-color: whitesmoke;
    height: 100vh;
  }

  .profileScrollBarContainer {
    display: none;
  }

  .profileHeader {
    /* border: solid; */
    margin-top: 25px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    width: 100%;
    /* background-color: rgb(219, 208, 208); */
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .nameSportTextContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    /* margin-bottom: 1%; */
  }

  .websiteUserName {
    font-size: 20px;
    color: black;
    font-weight: 500;
  }

  .websiteSportPositionText {
    font-size: 16px;
    color: black;
    font-weight: 480;
    margin-top: 5px;
  }

  .locationIconTextContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    margin-top: 5px;
  }

  .locationText {
    font-size: 14px;
    color: black;
    font-weight: 400;
    margin-left: 5px;
    /* text-align: center; */
  }

  .profileTextContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 5px;
  }

  .profileImage {
    border-radius: 1000px;
    border: 3px solid white;
    object-fit: cover;
    width: 120px;
    height: 120px
  }

  .locationIconTextContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 5px;
  }

  .socialIconsRow {
    display: flex;
    flex-direction: row;
    width: 250px;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 20px;

    /* justify-content: flex-start; */
  }

  .socialIcon {
    margin: 0px 5px;
  }

  .socialIcon:hover {
    /* margin-right: 5%; */
    cursor: pointer;
  }
  .bioContainer {
    width: 90%;
    margin-left: 5%;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: center;
    padding: 0% 3%;
    background-color: white;
  }

  .bioContainer > h1 {
    font-size: 16px;
    font-weight: bold;
    margin: 20px 0px;
    font-family: 'Montserrat', sans-serif;
  }

  .bioContainer > p {
    font-size: 14px;
    font-weight: 400;
    margin: 20px 0px;
  }

  .profileItemListHeader {
    font-size: 16px;
    font-weight: bold;
    margin: 20px 0px;
    font-family: 'Montserrat', sans-serif;
  }

  .profileItemListContainer {
    margin-top: 20px;
    width: 90%;
    display: flex;
    flex-direction: column;
    background-color: white;
    margin-left: 5%;
    /* border-radius: 10px; */
    padding: 0% 3%;
  }

  .seeMoreSeeLessItemButton {
    border: none;
    background-color: transparent;
    width: 106.5%;
    /* margin: 0% -20%; */
    align-self: center;
    height: 60px;
    /* margin: 20px 0px; */
    color: #3eb489;
    font-size: 15px;
    font-weight: 500;
    margin-top: -20px;
    font-family: 'Montserrat', sans-serif;
  }

  .seeMoreSeeLessItemButton:hover {
    cursor: pointer;
    background-color: aliceblue;
  }

  .spogoLogo {
    display: flex;
    /* width: 100%; */
    /* align-self: center;
        align-items: center; */
  }

  .spogoLogo > img {
    display: block;
    object-fit: contain;
    margin: 50px auto;
    width: 30%;
  }

  .videoItemArrayList {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
  }

  .wildcardLinkModalContentContainer > h1 {
    margin: 4% 0%;
    font-size: 20px;
    font-family: 'Montserrat', sans-serif;
    text-align: center;
  }

  .wildcardLinkModalContentContainer > p {
    margin: 2% 0%;
    font-size: 16px;
    text-align: center;
    font-family: 'Open Sans', sans-serif;
  }

  .wildcardModalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(34, 31, 31, 0.8);
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .wildcardLinkModalContainer {
    background-color: white;
    left: 20%;
    right: 20%;
    position: absolute;
    padding: 3% 2%;
    border-radius: 10px;
    outline: none;
  }

  .wildcardLinkModalButtonsContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 5%;
  }

  .wildcardLinkModalButton {
    flex: 0.5;
    margin: 0% 2%;
    border: none;
    background-color: #3eb489;
    color: black;
    padding: 5% 0%;
    border-radius: 5px;
  }

  .componentHeaderDivider {
    width: 100%;
  }

  .componentBottomDivider {
    width: 100%;
    margin-bottom: 20px;
    margin-top: -1px;
  }

  .userNotFoundErrorContainer {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .errorMessageContainer {
    display: flex;
    width: 70%;
    justify-content: space-between;
    margin-bottom: 2%;
    /* justify-content: center; */
  }

  .errorMessageTextContainer {
    border: 1px solid transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    width: 100%;
    height: auto;
    box-shadow: 0 4px 8px 0 rgba(66, 65, 65, 0.203),
      0 6px 20px 0 rgba(161, 155, 155, 0.59);
    transition: 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
    margin: 0% 2%;
    padding: 4% 3%;
    display: flex;
    flex-direction: column;
    z-index: 1;
  }

  .errorHeaderText {
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
    font-size: 30px;
    margin-bottom: 2%;
    color: #3eb489;
  }

  .errorHeadlineText {
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
  }

  .returnHomeText {
    font-weight: 500;
    text-decoration: underline;
    color: #3eb489;
    cursor: pointer;
  }

  .errorImageContainer {
    display: none;
  }

  .errorImage {
    object-fit: contain;
    height: 600px;
    margin-left: 10%;
    /* margin-top: 10%; */
  }

  .userNotFoundSpogoLogo {
    display: flex;
    width: 100%;
  }

  .userNotFoundSpogoLogo > img {
    display: block;
    object-fit: contain;
    margin: 0px auto;
    width: 50%;
  }

  .userNotFoundSpogoLogo > img:hover {
    cursor: pointer;
  }
}

@media screen and (min-width: 1200px) {
  .ProfileScreenContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: whitesmoke;
    overflow-x: hidden;
    height: 100vh;
  }

  .profileScrollBarContainer {
    position: fixed;
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-top: 20%;
    margin-left: 5%;
    padding: 20px;
    border-radius: 10px;
    /* background-color: rgb(235, 223, 223); */
  }

  .profileSideBarItem {
    margin-top: 20px;
    transition: cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  .profileSideBarItem:hover {
    cursor: pointer;
    color: rgb(68, 50, 50);
    font-weight: bold;
  }

  .profileSideBarItem:active {
    transform: scale(1.05);
    font-size: 16;
    /* font-weight: bold; */
  }

  .profileChatRightTab {
    width: 20%;
    height: 200px;
    /* background-color: white; */
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .middleProfileContent {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 20%;
  }

  .profileHeader {
    /* border: solid; */
    margin-top: 25px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    width: 100%;
    /* background-color: rgb(219, 208, 208); */
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .nameSportTextContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    /* margin-bottom: 1%; */
  }

  .websiteUserName {
    font-size: 20px;
    color: black;
    font-weight: 500;
  }

  .websiteSportPositionText {
    font-size: 16px;
    color: black;
    font-weight: 480;
    margin-top: 5px;
  }

  /*   .nameSportDivider {
    padding: 0% 3%;
    font-size: 30px;
  }

  .locationIconMediaTextContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
 */

  .locationIconTextContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    margin-top: 5px;
  }

  .locationText {
    font-size: 14px;
    color: black;
    font-weight: 400;
    margin-left: 5px;
    /* text-align: center; */
  }

  .profileTextContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 5px;
  }

  .profileImage {
    border-radius: 1000px;
    border: 3px solid white;
    object-fit: cover;
    width: 120px;
    height: 120px;
  }

  .socialIconsRow {
    display: flex;
    flex-direction: row;
    width: 250px;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 20px;

    /* justify-content: flex-start; */
  }

  .socialIcon {
    margin: 0px 5px;
  }

  .socialIcon:hover {
    /* margin-right: 5%; */
    cursor: pointer;
  }

  .bioContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: center;
    padding: 0% 3%;
    background-color: white;
  }

  .bioContainer > h1 {
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    font-weight: bold;
    margin: 20px 0px;
  }

  .bioContainer > p {
    font-size: 14px;
    font-weight: 400;
    margin: 20px 0px;
  }

  .seeLessButton:hover {
    cursor: pointer;
  }

  .seeMoreButton:hover {
    cursor: pointer;
  }

  .componentHeaderDivider {
    width: 100%;
  }

  .seeMoreSeeLessItemButton {
    border: none;
    background-color: transparent;
    width: 106.5%;
    margin-top: -20px;
    /* margin: 0% -20%; */
    align-self: center;
    height: 60px;
    /* margin: 20px 0px; */
    color: #3eb489;
    font-size: 15px;
    font-weight: 500;
    font-family: 'Montserrat', sans-serif;
  }

  .seeMoreSeeLessItemButton:hover {
    cursor: pointer;
    background-color: aliceblue;
  }

  .componentBottomDivider {
    width: 100%;
    margin-bottom: 20px;
    margin-top: -1px;
  }

  .profileItemListHeader {
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    font-weight: bold;
    margin: 20px 0px;
  }

  .profileItemListContainer {
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: white;
    /* border-radius: 10px; */
    padding: 0% 3%;
  }

  .spogoLogo {
    display: flex;
    /* width: 100%; */
    /* align-self: center;
          align-items: center; */
  }

  .spogoLogo > img {
    display: block;
    object-fit: contain;
    margin: 50px auto;
    width: 30%;
  }

  .spogoLogo > img:hover {
    cursor: pointer;
  }

  .videoItemArrayList {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    width: 100%;
    margin-bottom: 20px;
  }

  .wildcardLinkModalContentContainer > h1 {
    margin: 4% 0%;
    font-size: 20px;
    font-family: 'Montserrat', sans-serif;
  }

  .wildcardLinkModalContentContainer > p {
    margin: 2% 0%;
    font-size: 16px;
  }

  .wildcardModalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(34, 31, 31, 0.8);
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .wildcardLinkModalContainer {
    background-color: white;
    position: absolute;
    padding: 3% 2%;
    border-radius: 10px;
    outline: none;
  }

  .wildcardLinkModalButtonsContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 5%;
  }

  .wildcardLinkModalButton {
    flex: 0.5;
    margin: 0% 2%;
    border: none;
    background-color: #3eb489;
    color: black;
    padding: 5% 0%;
    border-radius: 5px;
    cursor: pointer;
  }

  .userNotFoundErrorContainer {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .errorMessageContainer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 2%;
    /* justify-content: center; */
  }

  .errorMessageTextContainer {
    border: 1px solid transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    width: 40%;
    height: auto;
    box-shadow: 0 4px 8px 0 rgba(66, 65, 65, 0.203),
      0 6px 20px 0 rgba(161, 155, 155, 0.59);
    transition: 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
    /* margin-left: 5%; */
    /* margin-top: 7%; */
    margin-left: 5%;
    padding: 4% 3%;
    display: flex;
    flex-direction: column;
    z-index: 1;
  }

  .errorHeaderText {
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
    font-size: 30px;
    margin-bottom: 2%;
    color: #3eb489;
  }

  .errorHeadlineText {
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
  }

  .returnHomeText {
    font-weight: 500;
    text-decoration: underline;
    color: #3eb489;
    cursor: pointer;
  }

  .errorImageContainer {
    display: flex;
    width: 50%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .errorImage {
    object-fit: contain;
    height: 600px;
    margin-left: 10%;
    /* margin-top: 10%; */
  }

  .userNotFoundSpogoLogo {
    display: flex;
    width: 100%;
  }

  .userNotFoundSpogoLogo > img {
    display: block;
    object-fit: contain;
    margin: 0px auto;
    width: 50%;
  }

  .userNotFoundSpogoLogo > img:hover {
    cursor: pointer;
  }

}
