.bottomLinksText {
  text-decoration: none;
  color: rgb(236, 227, 227);
  margin-bottom: 5%;
}

.bottomLinksText:hover {
  color: white;
}

.footer {
  background-color: #3eb489;
  margin-top: 5%;
  padding: 5%;
  display: flex;
  flex-direction: column;
  /* position:absolute;
  left:0;
  bottom:0;
  right:0; */
}

.footerLogoContainer {
  display: flex;
  
  flex: 0.5;
}

.footerTabsContainer {
  display: flex;
  flex: 0.5;
  justify-content: center;
}

.logoAndWebLinksContainer {
  display: flex;
  align-items: flex-start;
}

.footerSpogoImg {
  object-fit: contain;
  height: 50px;
}

.footerContent {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
}

.footerColumnContainer {
  height: 100%;
  text-decoration: none;
}

.footerColumnContainer > h2 {
  color: white;
  margin-bottom: 5%;
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
}

.footerColumnContainer > p {
  color: rgb(236, 227, 227);
  margin-bottom: 5%;
  font-size: 16px;
  text-decoration: none;
}

.footerColumnContainer > p:hover {
  color: white;
  cursor: pointer;
}

.socialMediaIconsAndCopyrightContainer {
  width: 185px;
  color: white;
  font-size: 12px;
  text-align: center;
}

.socialIconsFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.socialIconFooter {
  /* margin-right: 10px; */
}

@media screen and (max-width: 960px) {
  .footer {
    background-color: #3eb489;
    margin-top: 5%;
    padding: 5%;
  }

  .footerLogoContainer {
    display: flex;
    margin: 8% 0%;
  }

  .footerTabsContainer {
    display: flex;
    width: 100%;
    /* align-items: center; */
    justify-content: center;
  }

  .logoAndWebLinksContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .footerSpogoImg {
    object-fit: contain;
    height: 50px;
    display: flex;
    align-items: center;
  }

  .footerColumnContainer {
    height: 100%;
    text-decoration: none;
  }

  .footerColumnContainer > h2 {
    color: white;
    margin-bottom: 5%;
    font-family: 'Montserrat', sans-serif;
    /* font-size: 15px; */
  }

  .footerColumnContainer > p {
    color: rgb(236, 227, 227);
    margin-bottom: 5%;
    text-decoration: none;
    /* font-size: 13px; */
  }

  .footerColumnContainer > p:hover {
    color: white;
    cursor: pointer;
  }

  .socialMediaIconsAndCopyrightContainer {
    color: white;
    font-size: 12px;
    align-self: center;
    margin-top: 5%;
  }
}

@media screen and (max-width: 460px) {
  .footerContent {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
  }

  .footerColumnContainer {
    height: 100%;
    margin: 5% 15%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
  }
}
