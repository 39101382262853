@media screen and (min-width: 960px) {
  .waitlistFullNameInput {
    margin-right: 20px;
  }

  .landingPageTopImageMockupContainer {
    flex: 0.5;
    display: flex;
    justify-content: center;
    /* height: fit-content; */
  }

  .landingPageTopImageMockupContainer > img {
    object-fit: contain;
    width: 600px;
    /* margin-left: 30%; */
    margin-top: 10%;
    margin-bottom: 10%;
    transition: transform 450ms;
    border-radius: 10px;
  }

  .productDescriptionContainer {
    display: flex;
    flex-direction: row;
    width: 1;
    /* border: solid; */
    height: fit-content;
    margin-top: 3%;
  }

  .productDescriptionContainerInverted {
    display: flex;
    flex-direction: row;
    width: 1;
    /* border: solid; */
    height: fit-content;
    margin-top: 3%;
  }
}

@media screen and (min-width: 960px) {
  .landingPageTopButtonsContainer {
    display: flex;
    width: 100%;
    min-width: 480px;
    /* justify-content: space-around; */
  }
}

.invalidEmailTextContainer {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-bottom: 16px;
}

.invalidText {
  color: red;
  font-size: 14px;
}

.landingPageBody {
  display: flex;
  flex-direction: column;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.topLandingPageContentBannerContainer {
  display: flex;
}

.landingPageWaitlistContentContainer {
  display: flex;
  flex-direction: column;
  flex: 0.5;
  height: 1;
  justify-content: center;
  padding-left: 5%;
  padding-bottom: 8%;
  font-family: "Montserrat", sans-serif;
}

.landingPageWaitlistContentContainer > p {
  margin: 12px 0%;
  font-size: 20px;
}

.waitlistInputContainer {
  display: flex;
}

.waitlistInputContainer > input {
  flex: 0.5;
  height: 50px;
  padding: 16px;
  border: 0.5px solid #c7c7c7;
  border-radius: 12px;
  background-color: transparent;
  color: #000;
  font-size: 14px;
  font-weight: 400;
  outline: none;
  transition: 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  margin-bottom: 20px;
}

.waitlistInputContainer > input:focus {
  border-width: 2px;
  border-color: #3eb489;
  color: black;
}

.waitlistButton {
  width: 45%;
  margin-right: 5%;
}

.waitlistButton > button {
  background-color: #22e09a;
  height: 50px;
  border-radius: 1000px;
  border: none;
  font-weight: bold;
  font-size: 14px;
  width: 100%;
  font-family: "Montserrat", sans-serif;
  margin-right: 50px;
  margin-top: 20px;
}

.waitlistButton > button:hover {
  cursor: pointer;
  background-color: #22d694;
  color: #000;
  transition: 0.5s ease-in-out;
}

.mainProductHeaderImage {
  object-fit: contain;
  /* height: 450px; */
  width: 45%;
}

.productBigImage {
  object-fit: contain;
  /* height: 450px; */
  width: 100%;
  /* margin-bottom: 5%; */
}

.mainProductHeader {
  margin-top: 10%;
  margin-bottom: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mainProductHeader > h1 {
  font-family: "Montserrat", sans-serif;
}

.productDescription {
  padding: 2% 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 0.5;
  /* border: solid; */
  text-align: left;
}

.productDescription > h1 {
  font-size: 28px;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
}

.productDescription > h2 {
  font-size: 20px;
  font-weight: 350;
  margin-top: 20px;
  /* font-family: 'Public Sans', sans-serif; */
}

/* New Work for styling  */

.h1_text {
  font-size: 28px;
  font-weight: 600;
}

.p_text {
  font-size: 26px;
  font-weight: 400;
  padding: 3% 0px;
}

.form {
  width: 100%;
}

.form_inputs > input:focus {
  border-width: 2px;
  border-color: #3eb489;
  color: black;
}

.image > img:hover {
  transform: scale(1.05);
}

.mainProductHeader > h1 {
  text-align: center;
}

.mainProductHeaderSubtitle {
  /* font-family: 'Open_Sans', sans-serif; */
  font-weight: 350;
  text-align: center;
  padding: 2% 25%;
  font-size: 20px;
}

.bottom_part {
  background-color: #3eb489;
  margin-top: 5%;
  padding: 5%;
  display: flex;
  flex-direction: column;
}

.bottomLogoContainer {
  display: flex;
  flex: 0.5;
}

.bottomTabsContainer {
  display: flex;
  flex: 0.5;
  justify-content: center;
}

.no_copyright {
  display: flex;
  align-items: flex-start;
}

.spogo_bottom {
  object-fit: contain;
  height: 50px;
}

.content {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
}

.header_column {
  height: 100%;
  text-decoration: none;
}

.header_column > h2 {
  color: white;
  margin-bottom: 5%;
  font-size: 16px;
}

.header_column > p {
  color: rgb(236, 227, 227);
  margin-bottom: 5%;
  font-size: 16px;
  text-decoration: none;
}

.header_column > p:hover {
  color: white;
  cursor: pointer;
}

.copyright {
  color: white;
  font-size: 12px;
}

@media screen and (max-width: 960px) {
  .landingPageBody {
    display: flex;
    flex-direction: column;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    position: relative;
    overflow-x: hidden;
  }

  .topLandingPageContentBannerContainer {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    /* justify-content: center; */
  }

  .landingPageWaitlistContentContainer {
    padding: 5% 5%;
    /* padding-left: 8%; */
    /* padding-top: 5%; */
    /* border: solid; */
  }

  .invalidEmailTextContainer {
    align-items: center;
    justify-content: center;
  }

  .h1_text {
    text-align: center;
    font-size: 28px;
    font-weight: 600;
  }

  .p_text {
    text-align: center;
    font-size: 26px;
    font-weight: 400;
    padding: 3% 0px;
  }

  .waitlistInputContainer {
    display: flex;
    flex-direction: column;
  }

  .waitlistInputContainer > input {
    flex: 1;
    height: 50px;
    padding: 16px;
    border: 0.5px solid #c7c7c7;
    border-radius: 12px;
    background-color: transparent;
    color: #000;
    font-size: 14px;
    font-weight: 400;
    outline: none;
    transition: 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    font-family: "DM Sans", sans-serif;
    margin-bottom: 20px;
  }

  .header_column {
    height: 100%;
    text-decoration: none;
  }

  .content {
    display: flex;
    /* padding: 0% 15%; */
    /* justify-content: space-evenly; */
  }

  .landingPageTopImageMockupContainer {
    flex: 0.5;
    display: flex;
    justify-content: center;
    margin-top: 10%;
    margin-bottom: 20%;
    /* height: fit-content; */
  }

  .landingPageTopImageMockupContainer > img {
    object-fit: contain;
    width: 600px;
    /* margin-left: 30%; */
    transition: transform 450ms;
    border-radius: 10px;
  }

  .form {
    width: 100%;
  }

  .form_inputs {
    justify-content: space-evenly;
    padding-bottom: 2%;
  }

  .form_inputs > input {
    /* padding: 16px; */
    border: 0.5px solid #c7c7c7;
    border-radius: 12px;
    background-color: transparent;
    color: #000;
    font-size: 14px;
    font-weight: 400;
    outline: none;
    transition: 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 95%;
    margin-top: 2%;
  }

  .productDescriptionContainer {
    display: flex;
    flex-direction: column-reverse;
    width: 1;
    /* border: solid; */
    height: fit-content;
    margin: 8% 0%;
  }

  .productDescriptionContainerInverted {
    display: flex;
    flex-direction: column;
    width: 1;
    /* border: solid; */
    height: fit-content;
    margin: 8% 0%;
  }

  .form_inputs > input:focus {
    border-width: 2px;
    border-color: #3eb489;
    color: black;
  }

  .landingPageTopButtonsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .waitlistButton {
    width: 95%;
  }

  .waitlistButton > button {
    background-color: #22e09a;
    height: 50px;
    border-radius: 1000px;
    border: none;
    font-weight: bold;
    font-size: 16px;
    font-family: "Montserrat", sans-serif;
  }

  .waitlistButton > button:hover {
    cursor: pointer;
    background-color: #22d694;
    color: #000;
    transition: 0.5s ease-in-out;
  }

  .image > img {
    object-fit: contain;
    transition: transform 450ms;
    border-radius: 10px;
    margin-right: 100px;
  }

  /* .image > img:hover {
    transform: scale(1.05);
  } */

  .mainProductHeader {
    margin-top: 10%;
    margin-bottom: 30%;
    display: flex;
    align-items: center;
  }

  .mainProductHeader > h1 {
    text-align: center;
    padding: 0px 5%;
  }

  .mainProductHeaderSubtitle {
    text-align: center;
    padding: 2% 5%;
  }

  .productBigImage {
    object-fit: contain;
    height: 300px;
    width: 100%;
    margin-bottom: 5%;
    padding: 0px 5%;
  }

  .bottom_part {
    background-color: #3eb489;
    margin-top: 5%;
    padding: 5%;
  }

  .bottomLogoContainer {
    display: flex;
    margin: 8% 0%;
  }

  .bottomTabsContainer {
    display: flex;
    width: 100%;
    /* align-items: center; */
    justify-content: center;
  }

  .no_copyright {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .spogo_bottom {
    object-fit: contain;
    height: 50px;
    display: flex;
    align-items: center;
  }

  .header_column {
    height: 100%;
    text-decoration: none;
  }

  .header_column > h2 {
    color: white;
    margin-bottom: 5%;
    /* font-size: 15px; */
  }

  .header_column > p {
    color: rgb(236, 227, 227);
    margin-bottom: 5%;
    text-decoration: none;
    /* font-size: 13px; */
  }

  .header_column > p:hover {
    color: white;
    cursor: pointer;
  }

  .copyright {
    color: white;
    font-size: 12px;
    align-self: center;
    margin-top: 5%;
  }

  .productDescription {
    padding: 2% 15%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 0.5;
    /* border: solid; */
    text-align: left;
  }

  .productDescription > h1 {
    font-size: 28px;
    font-weight: bold;
  }

  .productDescription > h2 {
    font-size: 20px;
    font-weight: 350;
  }
}

@media screen and (max-width: 460px) {
  .landingPageTopImageMockupContainer > img {
    width: 100%;
    padding: 0% 4%;
  }
}

@media screen and (max-width: 460px) {
  .content {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
  }

  .header_column {
    height: 100%;
    margin: 5% 15%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
  }

  .productDescription > h2 {
    font-size: 18px;
    font-weight: 350;
  }

  .landingPageTopButtonsContainer {
    display: flex;
    flex-direction: column;
  }
}
