.TitleContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Montserrat', sans-serif;
}


.FAQHeaderContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 2.5% 0%;
}

.FAQHeaderContainer > h1 {
    font-size: 20px;
    font-weight: 500;
    padding-right: 20%;
}

.FAQSectionContainer {
    padding: 0% 25%;
    margin-top: 3%;
}

.FAQSectionHeader > h1 {
    font-size: 26px;
    margin-bottom: 2%;
    font-family: 'Montserrat', sans-serif;
}

.FAQAnswer {
    padding-top: 1%;
    padding-bottom: 1%;
    padding-right: 5%;
}

.FAQAnswer > h1 {
    font-weight: 400;
    font-size: 17px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
     -khtml-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.missionStatementLink {
    font-weight: 400;
    font-size: 17px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
     -khtml-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    text-decoration: none;
    color: #3eb489;
}

.openCloseIcon {
    padding: 0% 0%;
}

.missionStatementHereLink {
    font-weight: 400;
    color: #3eb489;
}

@media screen and (max-width: 1400px) {
    .FAQSectionContainer {
        padding: 0% 15%;
    }

    .FAQHeaderContainer > h1 {
        font-size: 16px;
    }
}

@media screen and (max-width: 750px) {
    .FAQHeaderContainer{
        flex-direction: column;
        align-items: center;
        text-align: center;
        justify-content: center;
    }

    .FAQHeaderContainer > h1 {
        padding-right: 0%;
        padding-top: 5%;
    }

    .openCloseIcon {
        display: none;
    }

    .FAQSectionContainer {
        display: flex;
        flex-direction: column;
        /* align-items: center; */
    }

    .FAQSectionHeader > h1 {
        font-size: 22px;
        margin-bottom: 2%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-top: 10%;
    }

    .FAQAnswer {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding-right: 0%;
    }

    .FAQAnswer > h1 {
        font-weight: 400;
        font-size: 17px;
        padding-top: 8%;
        padding-bottom: 3%;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
         -khtml-user-select: none;
           -moz-user-select: none;
            -ms-user-select: none;
                user-select: none;
    }
}