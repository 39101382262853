.bodyContainer {
    padding: 0px 35%;
}

.errorContainer {
  border: 1px solid transparent;
  border-radius: 10px;
  align-self: center;
  height: 300px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  transition: 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.errorContainer > p {
    font-weight: 500;
    font-size: 18px;
}

.errorLinkButton {
    text-decoration: none;
    padding: 10% 25%;
}

.errorButton {
  background-color: #3eb489;
  width: 200px;
  height: 50px;
  border-radius: 12px;
  border: none;
  font-weight: bold;
  font-size: 16px;
  font-family: 'DM Sans', sans-serif;
  text-decoration: none;
}

.errorButton:hover {
  cursor: pointer;
  background-color: #22d694;
  color: #000;
  transition: 0.5s ease-in-out;
}

@media screen and (max-width: 1000px) {
    .bodyContainer {
        padding: 0px 25%;
    } 
  }

  @media screen and (max-width: 650px) {
    .bodyContainer {
        padding: 0px 15%;
    } 
  }

  @media screen and (max-width: 450px) {
    .bodyContainer {
        padding: 0px 5%;
    } 
  }